/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/**
 * Used for the basic page layout.
 */
import {
    OnlinePatientManagementLayout,
    IMenuItem,
    OnlinePatientManagementContext,
    InstitutionsByCodesRoute,
    InstitutionsContext,
    RouteLoading,
    CrfFormByCodesRoutes,
    PatientSummaryByStudyNumberRoute,
    useIsMobile,
    ICrfFormMapping
} from '@ngt/opms';

import { SaeByPatientStudyNumberRoute } from '@ngt/opms-sae'

import { usePermissionsByIds } from '@ngt/opms-trogapi'

import { RequestState } from '@ngt/request-utilities';

/**
 * Used for font styling.
 */
import { Typography } from '@material-ui/core';

/**
 * Used to display icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Used for the copyright icon.
 */
import { faCopyright } from '@fortawesome/pro-regular-svg-icons/faCopyright';

/**
 * Used for the patient icon.
 */
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';

/**
 * Used for the ecrf icon.
 */
import { faList } from '@fortawesome/pro-duotone-svg-icons/faList';

/**
 * Used for the sae icon.
 */
import { faFileMedical as saeIcon } from '@fortawesome/pro-duotone-svg-icons/faFileMedical';

/**
 * Used for the screening log icon.
 */
import { faFileAlt as screeningLogIcon } from '@fortawesome/pro-duotone-svg-icons/faFileAlt';

import { faFileChartColumn } from '@fortawesome/pro-duotone-svg-icons';

import { faFirstAid } from '@fortawesome/pro-duotone-svg-icons/faFirstAid';

import { Switch, Redirect, Route } from 'react-router-dom';

import { DepotManagement, DrugManagementContext, AdministerDepot, BatchManagement, DrugManagementPage, AdministerPharmacy, DrugDispensations, IDrugManagementContext } from '@ngt/opms-drugmanagement';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

import Registration from './containers/Registration';

import PatientSummary from './containers/PatientSummary';

import ReportsPage from './containers/reports/ReportsPage';

import General from './containers/form/General';

import CancerDetails from './containers/form/CancerDetails';

import ClinicalAssessment from './containers/form/ClinicalAssessment';

import AdditionalEligibilityConfirmation from './containers/form/AdditionalEligibilityConfirmation';

import AdverseEvent from './containers/form/AdverseEvent';

import ConcomitantMedication from './containers/form/ConcomitantMedication';

import DiseaseAssessment from './containers/form/DiseaseAssessment';

import DemographicDetails from './containers/form/DemographicDetails';

import Bloods from './containers/form/Bloods';

import Urinalysis from './containers/form/Urinalysis';

import Ecg from './containers/form/Ecg';

import BiomarkerSample from './containers/form/BiomarkerSample';

import EligibilityConfirmation from './containers/form/EligibilityConfirmation';

import SourceDocuments from './containers/form/SourceDocuments';

import * as Dtos from './api/dtos';

import SaesPage from './containers/sae/SaesPage';

import SaeViewPage from './containers/sae/SaeViewPage';

import SaeNotifyPage from './containers/sae/SaeNotifyPage';
import ScreeningSummaryPage from './containers/screeninglog/ScreeningSummaryPage';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * constants
 * ----------------------------------------------------------------------------------
 */

const formMapping: Record<string, ICrfFormMapping> = {
    "general": {
        components: <General />
    },
    "cancer-details": {
        components: <CancerDetails />
    },
    "clinical-assessments": {
        components: <ClinicalAssessment />
    },
    "additional-eligibility-confirmation": {
        components: <AdditionalEligibilityConfirmation />
    },
    "adverse-event-form": {
        components: <AdverseEvent />
    },
    "concomitant-medication-form": {
        components: <ConcomitantMedication />
    },
    "disease-assessment": {
        components: <DiseaseAssessment />
    },
    "demographic-details": {
        components: <DemographicDetails />
    },
    "bloods": {
        components: <Bloods />
    },
    "urinalysis": {
        components: <Urinalysis />
    },
    "ecg": {
        components: <Ecg />
    },
    "biomarker-samples": {
        components: <BiomarkerSample />
    },
    "eligibility-confirmation": {
        components: <EligibilityConfirmation />
    },
    "source-documents": {
        components: <SourceDocuments />
    }
}

const permissions: Dtos.Permission[] = [
    Dtos.Permission.SaeView,
    Dtos.Permission.DrugManagementView,
    Dtos.Permission.ScreeningLogView,
    Dtos.Permission.ScreeningLogUpdate,
    Dtos.Permission.DrugManagementDispenseDrug,
    Dtos.Permission.DrugManagementAdminister,
    Dtos.Permission.DrugManagementAdministerPharmacyStock
];

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const App: React.FunctionComponent = () => {

    const [[canViewSae, canViewDrugManagement, canViewScreeningLog, canUpdateScreeningLog, canDispenseDrug, canAdministerDrugManagement, canAdministerPharmacyStock], permissionLoadState] = usePermissionsByIds(permissions, null, null, null, null, true);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);
    const drugManagementContext = React.useContext<IDrugManagementContext>(DrugManagementContext);
    const drugManagementBaseUrl = drugManagementContext?.baseUrl;

    const menuItems: IMenuItem[] = React.useMemo(() => {
        let items: IMenuItem[] = [];

        if (canViewScreeningLog) {
            items.push({
                url: '/screeningsummary',
                label: 'Pre-screening Summary Log',
                external: false,
                newTab: false,
                icon: screeningLogIcon
            });
        }

        items.push(
            {
                url: '/registration',
                label: 'Registration',
                external: false,
                newTab: false,
                icon: faUser
            }
        );

        items.push(
            {
                url: 'https://ecrf.trog.com.au',
                label: 'eCRF',
                external: true,
                newTab: true,
                icon: faList
            }
        );

        if (canViewSae) {
            items.push({
                url: '/sae',
                label: 'SAE Electronic Review',
                external: false,
                newTab: false,
                icon: saeIcon
            });
        }

        if (canViewDrugManagement) {
            items.push({
                url: drugManagementBaseUrl,
                label: 'Drug Management',
                external: false,
                newTab: false,
                icon: faFirstAid
            });
        }

        if (canViewScreeningLog) {
            items.push({
                url: '/reports',
                label: 'Reports',
                external: false,
                newTab: false,
                icon: faFileChartColumn
            });
        }

        return items;
    }, [canViewSae, canViewDrugManagement, drugManagementBaseUrl]);

    const isMobile = useIsMobile();

    const loadingPermissions = permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending;


    const { institutions } = React.useContext(InstitutionsContext);
    const noAccess: boolean = React.useMemo(() => {
        if (canViewSae ||
            canViewDrugManagement ||
            loadingPermissions) {
            return false;
        }
        if (institutions && institutions.length > 0) {
            return false;
        }
        return true;
    }, [canViewSae, canViewDrugManagement, drugManagementBaseUrl, loadingPermissions, institutions]);

    if (noAccess) {
        return <OnlinePatientManagementLayout
            menuItems={[]}
            footer={
                isMobile ?
                    undefined :
                    <Typography variant="caption">
                        Copyright <FontAwesomeIcon icon={faCopyright} fixedWidth /> 2021 Trans Tasman Radiation Oncology Group (TROG).
                    </Typography>
            }>
            <div className="container bg-white py-3 box-shadow-x">
                <div className="row">
                    <div className="col" style={{ textAlign: 'center', padding: '1em' }}>
                        <h1 className="text-danger p-3" style={{ color: 'red' } }>
                            <FontAwesomeIcon icon={faUser} fixedWidth />&nbsp;No Access</h1>
                        <h3 className="p-3">This user account has not been assigned any roles for this website.</h3>
                        <h5 className="p-3">
                            If you believe that you should have access please contact TROG for help.
                        </h5>
                    </div>
                </div>
            </div>
        </OnlinePatientManagementLayout>
    }

    return (
        <OnlinePatientManagementLayout
            menuItems={menuItems}
            footer={
                isMobile ?
                    undefined :
                    <Typography variant="caption">
                        Copyright <FontAwesomeIcon icon={faCopyright} fixedWidth /> 2021 Trans Tasman Radiation Oncology Group (TROG).
                    </Typography>
            }
        >
            {loadingPermissions && <RouteLoading />}
            <React.Suspense fallback={<RouteLoading />}>
                <div style={{
                    display: loadingPermissions ?
                        'none' :
                        undefined
                }}>
                    <Switch>
                        <CrfFormByCodesRoutes
                            path={[
                                `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/add-patient`
                            ]}
                            eventDefinitionCode="registration"
                            eventRepeat={1}
                            formDefinitionCode="general"
                            formRepeat={1}
                            createPatient
                            resolveBeforeLoad
                            formMapping={formMapping}
                        />
                        <CrfFormByCodesRoutes
                            path={[
                                `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:${onlinePatientManagement.routeParameters.eventDefinitionCode}/:${onlinePatientManagement.routeParameters.eventRepeat}/:${onlinePatientManagement.routeParameters.formDefinitionCode}/:${onlinePatientManagement.routeParameters.formRepeat}`
                            ]}
                            resolveBeforeLoad
                            formMapping={formMapping}
                        />
                        <PatientSummaryByStudyNumberRoute
                            path={[
                                `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}`
                            ]}
                            resolveBeforeLoad
                        >
                            <PatientSummary />
                        </PatientSummaryByStudyNumberRoute>
                        <InstitutionsByCodesRoute
                            path={[
                                `/registration/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                '/registration'
                            ]}
                        >
                            <Registration />
                        </InstitutionsByCodesRoute>
                        <SaeByPatientStudyNumberRoute
                            path={[
                                `/sae/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:saeNumber/notify/:notifyMode`,
                            ]}
                            resolveBeforeLoad
                            key="sae-notification-page"
                        >
                            <SaeNotifyPage />
                        </SaeByPatientStudyNumberRoute>
                        <SaeByPatientStudyNumberRoute
                            path={[
                                `/sae/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:saeNumber/:viewMode`,
                                `/sae/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:saeNumber`,
                            ]}
                            resolveBeforeLoad
                            key="sae-view-page"
                        >
                            <SaeViewPage />
                        </SaeByPatientStudyNumberRoute>
                        <InstitutionsByCodesRoute
                            path={[
                                `/sae/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                '/sae'
                            ]}
                        >
                            <SaesPage />
                        </InstitutionsByCodesRoute>
                        <Route
                            path={[
                                `${drugManagementBaseUrl}/batches`
                            ]}
                        >
                            <BatchManagement />
                        </Route>
                        <Route
                            path={[
                                `${drugManagementBaseUrl}/depots`
                            ]}
                        >
                            <DepotManagement />
                        </Route>
                        <Route
                            path={[
                                `${drugManagementBaseUrl}/depot/:depotId`
                            ]}>
                            <AdministerDepot />
                        </Route>
                        <Route
                            path={[
                                `${drugManagementBaseUrl}/pharmacy/:pharmacyId`
                            ]}
                        >
                            <AdministerPharmacy
                                canAdministerDrugManagement={canAdministerDrugManagement}
                                canAdministerPharmacyStock={canAdministerPharmacyStock}
                            />
                        </Route>
                        <PatientSummaryByStudyNumberRoute
                            path={[
                                `/drug-management/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/drug-dispensation`
                            ]}
                        >
                            <DrugDispensations canAdministerDrugManagement={canAdministerDrugManagement} canDispenseDrug={canDispenseDrug} />
                        </PatientSummaryByStudyNumberRoute>
                        {
                            !loadingPermissions && (
                                <Route
                                    path={[
                                        `${drugManagementBaseUrl}/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                        `${drugManagementBaseUrl}`
                                    ]}>
                                    <DrugManagementPage canAdministerDrugManagement={canAdministerDrugManagement} canViewDrugManagement={canViewDrugManagement} />
                                </Route>
                            )
                        }
                        <InstitutionsByCodesRoute
                            path={[
                                `/reports/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                '/reports'
                            ]}>
                            <ReportsPage />
                        </InstitutionsByCodesRoute>
                        <InstitutionsByCodesRoute
                            path={[
                                `/screeningsummary/:${onlinePatientManagement.routeParameters.institutionCode}/:year`,
                                `/screeningsummary/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                '/screeningsummary'
                            ]}
                        >
                            <ScreeningSummaryPage />
                        </InstitutionsByCodesRoute>
                        {!loadingPermissions && <Redirect path="*" to="/registration" />}
                    </Switch>
                </div>
            </React.Suspense>
        </OnlinePatientManagementLayout>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default App;
