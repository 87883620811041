import { SaeExtension } from '@ngt/opms-sae'
import { RequestState } from '@ngt/request-utilities';

import * as Dtos from './api/dtos';

import logo from './assets/img/trog_logo.png';
import { responsiveFontSizes } from '@material-ui/core';
import { authenticatedUserActions, IAuthenticatedUserStore } from '@ngt/opms/dist/store/modules/authenticatedUser';
import { TrogApiExtension } from '@ngt/opms-trogapi';
import { permissionsActions } from '@ngt/opms-trogapi/dist/store/modules/utility/permissions';
import OnlinePatientManagement from '@ngt/opms-trogapi/dist/TrogApiExtension';
import { DrugManagementExtension } from '@ngt/opms-drugmanagement';
import { ScreeningLogExtension } from '@ngt/opms-screeninglog';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const user = global.AUTH as Dtos.AuthenticateResponse;
const formMetadata = global.FORM_METADATA as Dtos.GetFormMetadataResponse;

if (!user) {
    if (global.location.search) {
       // global.location.href = '/auth/identity-server' + global.location.search;
        global.location.href = '/auth/openiddict' + global.location.search;
    } else {
       // global.location.href = '/auth/identity-server';
        global.location.href = '/auth/openiddict';
    }
}

const onlinePatientManagement = new OnlinePatientManagement({
    trialOptions: {
        organisationLogo: logo,
        organisationName: "Trans Tasman Radiation Oncology Group ",
        trialName: "TROG 20.01 CHEST RT"
    },
    dtos: Dtos,
    storeOptions: {
        preloadState: {
            authenticatedUser: {
                user,
                loadState: {
                    state: RequestState.Success
                }
            }
        }
    },
    themeOptions: {
        beforeThemeCreated: themeOptions => {
            return {
                palette: {
                    primary: {
                        main: '#00447a'
                    },
                    secondary: {
                        main: '#fea002' //'#2e86ab'
                    }
                },
                typography: themeOptions.typography
            };
        },
        afterThemeCreated: theme => {
            return responsiveFontSizes(theme);
        }
    },
    formMetadata: formMetadata?.formMetadata,
    extensions: [
        new TrogApiExtension({

        }),
        new DrugManagementExtension({
            defaultUnit: 'Vial',
            randomisedStatus: Dtos.PatientStateType.Registered,
            treatments: []
        }),
        new SaeExtension({
            hasMedicalReview: false,
            investigatorRoles: [3, 4, 5]
        }),
        new ScreeningLogExtension({

        })
    ]
});

onlinePatientManagement.serviceStackClient.responseFilter = (res: Response) => {
    const state = onlinePatientManagement.store.getState() as IAuthenticatedUserStore;

    //if (res.headers.has('idsrv-user-status') &&
    //    res.headers.get('idsrv-user-status') === 'unauthenticated' &&
    //    state.authenticatedUser.user) {
    //    onlinePatientManagement.store.dispatch(authenticatedUserActions.signoutAuthenticatedUser())
    //}
    //else if (res.headers.has('idsrv-user-update') &&
    //    res.headers.get('idsrv-user-update') === 'true' &&
    //    state.authenticatedUser.loadState.state !== RequestState.Pending) {
    //    console.log('user updated');
    //    console.log(res);
    //    onlinePatientManagement.store.dispatch(authenticatedUserActions.checkAuthenticatedUser());
    //    onlinePatientManagement.store.dispatch(permissionsActions.refresh());
    //}

    if (res.headers.has('openiddict-user-status') &&
        res.headers.get('openiddict-user-status') === 'unauthenticated' &&
        state.authenticatedUser.user) {
        onlinePatientManagement.store.dispatch(authenticatedUserActions.signoutAuthenticatedUser())
    }
    else if (res.headers.has('openiddict-user-update') &&
        res.headers.get('openiddict-user-update') === 'true' &&
        state.authenticatedUser.loadState.state !== RequestState.Pending) {
        console.log('user updated');
        console.log(res);
        onlinePatientManagement.store.dispatch(authenticatedUserActions.checkAuthenticatedUser());
        onlinePatientManagement.store.dispatch(permissionsActions.refresh());
    }
}

onlinePatientManagement.serviceStackClient.exceptionFilter = (res: Response, error: any) => {
    if (res.status === 401) {
        //global.location.href = `/auth/identity-server?redirect=${encodeURIComponent(global.location.pathname + global.location.search)}`;
        global.location.href = `/auth/openiddict?redirect=${encodeURIComponent(global.location.pathname + global.location.search)}`;
    }
};

export default onlinePatientManagement;